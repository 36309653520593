import { createContext, ReactNode, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import { destroyCookie, setCookie } from "nookies";

import { api } from "../services/apiClient";

type AuthContextData = {
    user: UserProps | undefined;
    isAuthenticated: boolean;
    signIn: (credentials: SignInProps) => Promise<void>;
    signOut: () => void;
    loading: boolean;
};

type UserProps = {
    id: string;
    name: string;
    login: string;
    email: string;
    empresaId: string;
    clienteId: string;
    moduloName: string;
    application: string;
    siteName: string;
};

type SignInProps = {
    login: string;
    password: string;
    clienteId: string;
    moduloName: string;
};

type AuthProviderProps = {
    children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
    const [user, setUser] = useState<UserProps | undefined>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(user !== undefined);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const MAX_AGE_15S = 3600;
    const LOADING_TIME = 17000;

    const signOut = useCallback(async () => {
        try {
            sessionStorage.removeItem("@auth.token");
            sessionStorage.removeItem("@auth.fileToken");
            if (user && user.clienteId && user.moduloName) {
                navigate(`/?module=${user.moduloName}&cliente=${user.clienteId}`);
            } else {
                navigate("/");
            }
        } catch {
            console.log("erro ao deslogar");
        }
    }, [user, navigate]);

    function clearCookie() {
        destroyCookie(null, "@auth.usr");
        destroyCookie(null, "@auth.pwd");
        destroyCookie(null, "@auth.app");
        destroyCookie(null, "@auth.arg");
    }

    useEffect(() => {
        // tentar pegar algo no cookie
        const token = sessionStorage.getItem("@auth.token");
        const fileToken = sessionStorage.getItem("@auth.fileToken");
        async function fetchUserData() {
            try {
                if (token) {
                    const response = await api.get("/usuario");
                    const {
                        id,
                        name,
                        login,
                        email,
                        empresaId,
                        clienteId,
                        moduloName,
                        application,
                        siteName,
                        usrso,
                        pwdso,
                    } = response.data;

                    // Extrai parâmetros de consulta da URL
                    const urlSearchParams = new URLSearchParams(window.location.search);
                    const params = Object.fromEntries(urlSearchParams.entries());
                    if (
                        params.module &&
                        params.cliente &&
                        moduloName &&
                        clienteId &&
                        (!params.module !== moduloName || !params.cliente !== clienteId)
                    ) {
                        sessionStorage.removeItem("@auth.token");
                        sessionStorage.removeItem("@auth.fileToken");
                        return;
                    }
                    setUser({
                        id,
                        name,
                        login,
                        email,
                        empresaId,
                        clienteId,
                        moduloName,
                        application,
                        siteName,
                    });

                    setIsAuthenticated(true);
                    setCookie(null, "@auth.usr", usrso, {
                        maxAge: MAX_AGE_15S, // Expirar em 15 segundos
                        path: "/", // Quais caminhos terao acesso ao cookie
                    });

                    setCookie(null, "@auth.pwd", pwdso, {
                        maxAge: MAX_AGE_15S,
                        path: "/",
                    });

                    setCookie(null, "@auth.app", application, {
                        maxAge: MAX_AGE_15S,
                        path: "*",
                    });

                    setCookie(null, "@auth.arg", `VIRTUAL=S;U=${id};LAYOUT=3;P=${fileToken}`, {
                        maxAge: MAX_AGE_15S,
                        path: "*",
                    });

                    navigate("/dashboard");
                    setTimeout(function () {
                        clearCookie();
                    }, LOADING_TIME);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.error("Erro ao buscar dados do usuário:", error);
                signOut(); // Chama a função signOut em caso de erro
            }
        }

        async function initializeUserData() {
            await fetchUserData();
        }
        if (!isAuthenticated && token) {
            initializeUserData();
        }
    }, [isAuthenticated, navigate, signOut]);

    async function signIn({ login, password, clienteId, moduloName }: SignInProps) {
        try {
            const auth = {
                username: process.env.REACT_APP_USERNAME_AUTH || "",
                password: process.env.REACT_APP_PASSWORD_AUTH || "",
            };
            const response = await api.post(
                "/login",
                {
                    login,
                    password,
                    clienteId,
                    moduloName,
                },
                { auth },
            );

            const { id, name, token, email, empresaId, application, siteName, usrso, pwdso, fileToken } = response.data;

            sessionStorage.setItem("@auth.token", token);
            sessionStorage.setItem("@auth.fileToken", fileToken);

            setCookie(null, "@auth.usr", usrso, {
                maxAge: MAX_AGE_15S,
                path: "/",
            });

            setCookie(null, "@auth.pwd", pwdso, {
                maxAge: MAX_AGE_15S,
                path: "/",
            });

            setCookie(null, "@auth.app", application, {
                maxAge: MAX_AGE_15S,
                path: "*",
            });

            setCookie(null, "@auth.arg", `VIRTUAL=S;U=${id};LAYOUT=3;P=${fileToken}`, {
                maxAge: MAX_AGE_15S,
                path: "*",
            });

            setUser({
                id,
                name,
                login,
                email,
                empresaId,
                clienteId,
                moduloName,
                application,
                siteName,
            });
            setIsAuthenticated(true);

            //Passar para proximas requisiçoes o nosso token
            api.defaults.headers["Authorization"] = `Bearer ${token}`;

            console.log("Login efetuado com sucesso!");
            //Redirecionar o user para /dashboard
            navigate("/dashboard");
            setLoading(false);
        } catch (err) {
            setLoading(false);
            // Verifica se o erro é do tipo AxiosError
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 403 && err.response?.data?.includes("Login ou senha inválidos")) {
                    toast.warning(
                        "Ops! Não foi possível acessar sua conta. Verifique se o login e a senha estão corretos e tente novamente.",
                    );
                } else if (
                    err.response?.status === 403 &&
                    err.response?.data?.includes("Escola está com o acesso bloqueado")
                ) {
                    toast.warning(
                        "O acesso à escola está temporariamente bloqueado. Por favor, entre em contato com um consultor Omega para mais informações.",
                    );
                } else if (
                    err.response?.status === 403 &&
                    err.response?.data?.includes("Usuário está com o acesso bloqueado")
                ) {
                    toast.warning(
                        "O acesso do usuário está temporariamente bloqueado. Por favor, entre em contato com um consultor Omega para mais informações.",
                    );
                } else {
                    toast.error("Erro ao acessar!");
                }
            } else {
                // Erro não relacionado ao Axios
                console.error("Erro desconhecido:", err);
                toast.error("Ocorreu um erro inesperado!");
            }
        }
    }

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, signIn, signOut, loading }}>
            {children}
        </AuthContext.Provider>
    );
}

export {};
