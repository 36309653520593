import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AuthProvider } from "./contexts/AuthContext";
import RoutesApp from "./routes";

import "react-toastify/dist/ReactToastify.css";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <AuthProvider>
                    <ToastContainer autoClose={3000} />
                    <RoutesApp />
                </AuthProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
